import { authHeader, base, viewpoint } from "$utils/api";


export async function fetchStatus(scope: string, valid: string = "") {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/detections/vehicles/status?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid
            )}&${authHeader}`
        )
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values));

    var target = json.vehicles.detections.status.for[scope];

    if (!target) return target;

    target.items = Object.entries(json.vehicles.detections.items).reduce(
        (acc, [key, value]) => {
            acc[key] = json.items[key] ?? value;
            return acc;
        },
        {} as Record<string, any>
    );

    for (const v1 of Object.values(target.for) as any[]) {
        if (v1.scope) v1.scope = json.items[v1.scope] ?? v1.scope;
        if (v1.vehicle) v1.vehicle = json.items[v1.vehicle] ?? v1.vehicle;
        if (v1.subject) v1.subject = json.items[v1.subject] ?? v1.subject;
        for (const [k2, v2] of Object.entries(v1.items as Record<string, any>)) {
            const resolved = v1.items[k2] = (json.items[v2] ?? json.items[k2] ?? v2); // expand
            if (resolved.file) resolved.file = json.items[resolved.file] ?? resolved.file;
        }
        if (v1.latest) v1.latest = json.items[v1.latest] ?? v1.latest;
        for (const [k2, v2] of Object.entries(v1.permits?.items as Record<string, string> ?? {})) {
            v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
        }
        for (const [k2, v2] of Object.entries(v1.violations?.items as Record<string, string> ?? {})) {
            v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
        }
        if (v1.violations?.latest) v1.violations.latest = json.items[v1.violations.latest] ?? v1.violations.latest;

    }

    return target;
}

// export async function fetchEnforce(scope: string, valid: string = "") {
//     if (!scope) return null;
//     const json = await Promise.all([
//         fetch(
//             `${base()}/vehicles/present/enforce?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
//                 valid
//             )}&${authHeader}`
//         )
//     ])
//         .then((values) => Promise.all(values.map((res) => res.json())))
//         .then((values) => Object.assign({}, ...values));

//     var target = json.vehicles.present.enforce.for[scope];

//     for (const item of Object.values(target.items)) {
//         item.property = json.items[item.scope] ?? item.property;
//         item.present.item = json.items[item.present.item] ?? item.present.item;
//         item.vehicle = json.items[item.vehicle] ?? item.vehicle;
//         if (item.warned?.items) Object.entries(item.warned.items).reduce((result, [k, v]) => {
//             result[k] = json.items[v] ?? v;
//             return result;
//         }, item.warned.items);
//         if (item.permitted?.items) Object.entries(item.permitted.items).reduce((result, [k, v]) => {
//             result[k] = json.items[v] ?? v;
//             return result;
//         }, item.permitted.items);
//         if (item.present?.items) Object.entries(item.present.items).reduce((result, [k, v]) => {
//             result[k] = json.items[v] ?? v;
//             return result;
//         }, item.present.items);
//     }

//     if (!target) return target;



//     // target.items = Object.entries(json.vehicles.detections.items).reduce(
//     //     (acc, [key, value]) => {
//     //         acc[key] = json.items[key] ?? value;
//     //         return acc;
//     //     },
//     //     {} as Record<string, any>
//     // );

//     // for (const v1 of Object.values(target.for) as any[]) {
//     //     if (v1.scope) v1.scope = json.items[v1.scope] ?? v1.scope;
//     //     if (v1.vehicle) v1.vehicle = json.items[v1.vehicle] ?? v1.vehicle;
//     //     if (v1.subject) v1.subject = json.items[v1.subject] ?? v1.subject;
//     //     for (const [k2, v2] of Object.entries(v1.items as Record<string, any>)) {
//     //         const resolved = v1.items[k2] = (json.items[v2] ?? json.items[k2] ?? v2); // expand
//     //         if (resolved.file) resolved.file = json.items[resolved.file] ?? resolved.file;
//     //     }
//     //     if (v1.latest) v1.latest = json.items[v1.latest] ?? v1.latest;
//     //     for (const [k2, v2] of Object.entries(v1.permits?.items as Record<string, string> ?? {})) {
//     //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
//     //     }
//     //     for (const [k2, v2] of Object.entries(v1.violations?.items as Record<string, string> ?? {})) {
//     //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
//     //     }
//     //     if (v1.violations?.latest) v1.violations.latest = json.items[v1.violations.latest] ?? v1.violations.latest;

//     // }

//     return target;
// }

export async function fetchCmd(client: string, cmd: any) {
    if (!client) return null;
    const json = await Promise.all([
        fetch(
            `https://events.propertyboss.io/lprd?id=${client}&${authHeader}`, {
            body: JSON.stringify({
                client,
                cmd
            }),
            method: "PATCH"
        }
        ),
    ])
}

export async function fetchPresent(scope: string, valid: string | null | undefined = "") {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/vehicles/present?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid ?? ""
            )}&${authHeader}`
        )
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values));

    var target = json.vehicles.present.for[scope];

    if (target?.items) Object.entries(target.items).reduce((result, [k, v]) => {
        result[k] = json.items[v] ?? v;
        return result;
    }, target.items);

    for (const item of Object.values(target.items)) {
        //item.property = json.items[item.scope] ?? item.property;
        if (item?.items) Object.entries(item.items).reduce((result, [k, v]) => {
            result[k] = json.items[v] ?? v;
            return result;
        }, item.items);
    }

    for (const item of Object.values(target.intervals.items)) {
        //item.property = json.items[item.scope] ?? item.property;
        if (item?.items) Object.entries(item.items).reduce((result, [k, v]) => {
            result[k] = json.items[v] ?? v;
            if (result?.[k]?.file) result[k].file = json.items[result[k].file] ?? result[k].file;
            return result;
        }, item.items);
    }

    if (!target) return target;



    // target.items = Object.entries(json.vehicles.detections.items).reduce(
    //     (acc, [key, value]) => {
    //         acc[key] = json.items[key] ?? value;
    //         return acc;
    //     },
    //     {} as Record<string, any>
    // );

    // for (const v1 of Object.values(target.for) as any[]) {
    //     if (v1.scope) v1.scope = json.items[v1.scope] ?? v1.scope;
    //     if (v1.vehicle) v1.vehicle = json.items[v1.vehicle] ?? v1.vehicle;
    //     if (v1.subject) v1.subject = json.items[v1.subject] ?? v1.subject;
    //     for (const [k2, v2] of Object.entries(v1.items as Record<string, any>)) {
    //         const resolved = v1.items[k2] = (json.items[v2] ?? json.items[k2] ?? v2); // expand
    //         if (resolved.file) resolved.file = json.items[resolved.file] ?? resolved.file;
    //     }
    //     if (v1.latest) v1.latest = json.items[v1.latest] ?? v1.latest;
    //     for (const [k2, v2] of Object.entries(v1.permits?.items as Record<string, string> ?? {})) {
    //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
    //     }
    //     for (const [k2, v2] of Object.entries(v1.violations?.items as Record<string, string> ?? {})) {
    //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
    //     }
    //     if (v1.violations?.latest) v1.violations.latest = json.items[v1.violations.latest] ?? v1.violations.latest;

    // }

    return target;
}

export async function fetchPresentEnforce(scope: string, valid: string | null | undefined = "") {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/vehicles/present/enforce?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid ?? ""
            )}&${authHeader}`
        )
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values));

    var target = json.vehicles.present.enforce.for[scope];
    if (json.services) {
        target.services = json.services;
        for (const [k, v] of Object.entries(json.services.items)) {
            target.services.items[k] = json.items[v] ?? v;
        }
    }

    for (const item of Object.values(target.items)) {
        item.property = json.items[item.scope] ?? item.property;
        item.present.item = json.items[item.present.item] ?? item.present.item;
        if (item.present.item.file) item.present.item.file = json.items[item.present.item.file] ?? item.present.item.file;
        item.vehicle = json.items[item.vehicle] ?? item.vehicle;
        if (item.warned?.items) Object.entries(item.warned.items).reduce((result, [k, v]) => {
            result[k] = json.items[v] ?? v;
            return result;
        }, item.warned.items);
        if (item.permitted?.items) Object.entries(item.permitted.items).reduce((result, [k, v]) => {
            v = result[k] = json.items[v] ?? v;
            if (v.source) v.source = json.items[v.source] ?? v.source;
            if (v.vehicle) v.vehicle = json.items[v.vehicle] ?? v.vehicle;
            return result;
        }, item.permitted.items);
        if (item.present?.items) Object.entries(item.present.items).reduce((result, [k, v]) => {
            v = result[k] = json.items[v] ?? v;
            if (v.file) v.file = json.items[v.file] ?? v.file;
            return result;
        }, item.present.items);
    }

    if (!target) return target;



    // target.items = Object.entries(json.vehicles.detections.items).reduce(
    //     (acc, [key, value]) => {
    //         acc[key] = json.items[key] ?? value;
    //         return acc;
    //     },
    //     {} as Record<string, any>
    // );

    // for (const v1 of Object.values(target.for) as any[]) {
    //     if (v1.scope) v1.scope = json.items[v1.scope] ?? v1.scope;
    //     if (v1.vehicle) v1.vehicle = json.items[v1.vehicle] ?? v1.vehicle;
    //     if (v1.subject) v1.subject = json.items[v1.subject] ?? v1.subject;
    //     for (const [k2, v2] of Object.entries(v1.items as Record<string, any>)) {
    //         const resolved = v1.items[k2] = (json.items[v2] ?? json.items[k2] ?? v2); // expand
    //         if (resolved.file) resolved.file = json.items[resolved.file] ?? resolved.file;
    //     }
    //     if (v1.latest) v1.latest = json.items[v1.latest] ?? v1.latest;
    //     for (const [k2, v2] of Object.entries(v1.permits?.items as Record<string, string> ?? {})) {
    //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
    //     }
    //     for (const [k2, v2] of Object.entries(v1.violations?.items as Record<string, string> ?? {})) {
    //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
    //     }
    //     if (v1.violations?.latest) v1.violations.latest = json.items[v1.violations.latest] ?? v1.violations.latest;

    // }

    return target;
}

export async function fetchVehiclePresentEnforce(scope: string, id: string, valid: string | nullish = "") {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/vehicles/${id}/present/enforce?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid ?? ""
            )}&${authHeader}`
        )
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values));

    var target = json.vehicles.present.enforce.for[scope];

    for (const item of Object.values(target.items)) {
        item.property = json.items[item.scope] ?? item.property;
        item.present.item = json.items[item.present.item] ?? item.present.item;
        if (item.present.item.file) item.present.item.file = json.items[item.present.item.file] ?? item.present.item.file;
        item.vehicle = json.items[item.vehicle] ?? item.vehicle;
        if (item.warned?.items) Object.entries(item.warned.items).reduce((result, [k, v]) => {
            result[k] = json.items[v] ?? v;
            return result;
        }, item.warned.items);
        if (item.permitted?.items) Object.entries(item.permitted.items).reduce((result, [k, v]) => {
            v = result[k] = json.items[v] ?? v;
            if (v.source) v.source = json.items[v.source] ?? v.source;
            if (v.vehicle) v.vehicle = json.items[v.vehicle] ?? v.vehicle;
            return result;
        }, item.permitted.items);
        if (item.present?.items) Object.entries(item.present.items).reduce((result, [k, v]) => {
            result[k] = json.items[v] ?? v;
            if (result[k].file) result[k].file = json.items[result[k].file] ?? result[k].file;
            return result;
        }, item.present.items);
    }

    if (!target) return target;



    // target.items = Object.entries(json.vehicles.detections.items).reduce(
    //     (acc, [key, value]) => {
    //         acc[key] = json.items[key] ?? value;
    //         return acc;
    //     },
    //     {} as Record<string, any>
    // );

    // for (const v1 of Object.values(target.for) as any[]) {
    //     if (v1.scope) v1.scope = json.items[v1.scope] ?? v1.scope;
    //     if (v1.vehicle) v1.vehicle = json.items[v1.vehicle] ?? v1.vehicle;
    //     if (v1.subject) v1.subject = json.items[v1.subject] ?? v1.subject;
    //     for (const [k2, v2] of Object.entries(v1.items as Record<string, any>)) {
    //         const resolved = v1.items[k2] = (json.items[v2] ?? json.items[k2] ?? v2); // expand
    //         if (resolved.file) resolved.file = json.items[resolved.file] ?? resolved.file;
    //     }
    //     if (v1.latest) v1.latest = json.items[v1.latest] ?? v1.latest;
    //     for (const [k2, v2] of Object.entries(v1.permits?.items as Record<string, string> ?? {})) {
    //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
    //     }
    //     for (const [k2, v2] of Object.entries(v1.violations?.items as Record<string, string> ?? {})) {
    //         v1.permits.items[k2] = json.items[v2] ?? json.items[k2] ?? v2;
    //     }
    //     if (v1.violations?.latest) v1.violations.latest = json.items[v1.violations.latest] ?? v1.violations.latest;

    // }

    return target;
}
