<script lang="ts">
  export let summary: any;
</script>

<aside class="disputed summary">
  <dl class="data">
    <dt>Disputed</dt>
    <dd>
      <data class="count" value={summary.items.disputed.count}
        >{summary.items.disputed.count}</data
      >
    </dd>
    <dt>Charges</dt>
    <dd>
      <data class="amount" value={summary.items.disputed.money.value}
        >{summary.items.disputed.money.display}</data
      >
    </dd>
    <dt>Bank Fees</dt>
    <dd>
      <data class="amount" value={summary.items.fees.money.value}
        >{summary.items.fees.money.display}</data
      >
    </dd>
  </dl>
</aside>
