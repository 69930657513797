<script context="module">
  import { time } from "$utils/timestores";
  import {
    usage,
    conflicts,
    enforcement,
    usageFor,
    permits,
    policies,
    recordPaymentMetrics,
    tenantExternalCRMStatus,
    tenantExternalCRMInfo,
    paymentsDisputed,
  } from "$utils/recordstores";
  import { externalCRM, units } from "$utils/propertystores";

  const now = time({ seconds: 60 });

  // tenantExternalCRMInfo.subscribe(($value) =>
  //   console.log("tenantintegration=", $value)
  // );
</script>

<script>
  import Record from "$components/record/RecordItem.svelte";
  import { comparer, dateAsc, dateDesc, textAsc } from "$utils/sort";
  import { addYears, parseISO } from "date-fns";
  import identity from "lodash-es/identity";
  import ItemsList from "$components/item/ItemsList.svelte";
  import { sortByCreatedDate } from "$components/components";
  import { onDestroy, onMount } from "svelte";
  import ResidentItem from "$components/ResidentItem.svelte";
  import VehicleItem from "$components/VehicleItem.svelte";
  import ViolationExceptionStatusItem from "$components/ViolationExceptionStatusItem.svelte";
  import EnforcementSummaryMapItem from "$components/enforcement/EnforcementSummaryMapItem.svelte";
  import UsageMeterSubjectItem from "$components/UsageMeterSubjectItem.svelte";
  import { route } from "$utils/router";
  import RecordArchivedItem from "$components/RecordArchivedItem.svelte";
  import RecordVersionsItem from "$components/RecordVersionsItem.svelte";
  import CountSummaryItem from "$components/CountSummaryItem.svelte";

  import EnforcementDetailsItem from "$components/enforcement/EnforcementDetailsItem.svelte";
  import PermitConflictsDetailstem from "$components/PermitConflictsDetailstem.svelte";
  import UnitFormatItem from "$components/unit/UnitFormatItem.svelte";
  import PermittableAlertItem from "$components/PermittableAlertItem.svelte";
  import ViolationExceptionAlertItem from "$components/ViolationExceptionAlertItem.svelte";
  import PropertySelectedMap from "$components/PropertySelectedMap.svelte";
  import RentableItem from "$components/rentable/RentableItem.svelte";
  import Time from "$components/Time.svelte";
  import Loading from "$components/Loading.svelte";
  import Info from "$components/Info.svelte";
  import ServiceSummaryItem from "$components/service/ServiceSummaryItem.svelte";
  import UnitInfoItem from "$components/unit/UnitInfoItem.svelte";
  import Warning from "$components/Warning.svelte";
  import RecordDetailsNotesFiles from "$components/RecordDetailsNotesFiles.svelte";
  import ServiceConnectedRecordItem from "$components/service/ServiceConnectedRecordItem.svelte";
  import ServiceLogo from "$components/service/ServiceLogo.svelte";
  import RecordPermitPolicies from "$components/permit/RecordPermitPolicies.svelte";
  import orderBy from "lodash-es/orderBy";
  import ComingSoon from "$components/promo/ComingSoon.svelte";
  import RecordDoNotPermit from "$components/permittable/RecordDoNotPermit.svelte";
  import TenantReset from "$components/tenant/TenantReset.svelte";
  import AuthCodeReset from "$components/authtoken/AuthCodeReset.svelte";
  import UnitStatusActionItem from "$components/unit/UnitStatusActionItem.svelte";
  import { title as proper } from "$utils/text";
  import TenantNotify from "$components/tenant/TenantNotify.svelte";
  import ContactCreate from "$components/contact/ContactCreate.svelte";
  import set from "lodash-es/set";
  import CopyText from "$components/copy/CopyText.svelte";
  import PaymentsDisputedSummary from "$components/payment/PaymentsDisputedSummary.svelte";
  import PaymentDisputedItem from "$components/payment/PaymentDisputedItem.svelte";

  // this data we get externally
  export let id = null;
  export let property = null;
  export let item = null;
  export let data = null; // this is the fast/frequent loaded now data
  //export let status = null;
  export let url = true;
  export let map = true;
  export let minimal = false;

  $: timezone = item?.timezone ?? property?.timezone;

  $: nowutc = $now;

  let attachments = {};
  let contacts = { items: {} };
  $: if (id && !data) {
    attachments = {}; // clear on change
    contacts = {};
  }

  $: if (data?.attachments?.["for"]?.[item?.id])
    attachments = data?.attachments?.["for"][item?.id];

  $: if (data?.contacts?.["for"]?.[item?.id])
    contacts = data?.contacts?.["for"][item?.id];

  $: console.log("contacts=", contacts);

  // $: console.log("tenant=", item);
  // $: console.log("details=", data);
  // $: console.log("$conflicts=", $conflicts);

  $: auth = Object.entries(
    (item && data?.authentication?.items[item.id]) || {}
  ).map(([id, value]) => ({
    type: "auth",
    id,
    display: value,
  }));

  //$: console.log("status=", $tenantExternalCRMStatus);

  $: statusAttention =
    !!$tenantExternalCRMStatus?.status?.check?.vacant ||
    !!$tenantExternalCRMStatus?.status?.check?.reoccupied;

  // $: console.log("Data", data);

  $: activeRecentUpcomingPermits = data?.permits; // loads active + future
  $: exceptions = data?.violations.exceptions; // loads current exception state

  $: permitHistory = $permits; // loads the last year

  $: [minutc, maxutc] =
    (item && item.valid.interval.split("/").map((d) => d && parseISO(d))) || [];

  $: archived = item && (!!item.valid.next || (maxutc && maxutc < nowutc)); // explicit next or past interval
  $: current = item && !item.valid.next;

  $: donotpermit = [data?.permittables.item].find(
    (item) => item && item.permittable === false
  ); // loads latest permittable item

  $: unit = item?.subject;
  $: parking = !!property?.parking?.enabled;
  $: amenities = !!property?.amenities?.enabled;
  $: amenitiesPolicies = $policies?.filter(
    (p) => p.amenity !== "parking" && p.audience.admin
  );
  $: welcome = !!property?.tenants.tour?.enabled;
  $: permittable =
    current &&
    !archived &&
    (false === $externalCRM?.connected ||
      false === $tenantExternalCRMStatus?.connected ||
      (true === $tenantExternalCRMStatus?.connected &&
        !statusAttention &&
        ((!!$tenantExternalCRMStatus.status.occupied &&
          (true || !$tenantExternalCRMStatus.status.pending.vacancy)) ||
          (!!$tenantExternalCRMStatus.status.vacant &&
            (true || !!$tenantExternalCRMStatus.status.pending.occupancy)))));

  //$: console.log("donotpermit=", donotpermit);

  $: activeParkingPermits = orderBy(
    Object.values(activeRecentUpcomingPermits?.items || {}).filter(
      (p) =>
        p.amenity == "parking" &&
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) > parseISO(p.valid.min.datetime) &&
        !(p.valid.max && parseISO(p.valid.max.datetime) < nowutc)
    ),
    [
      function (a) {
        return (
          (a.valid.max?.datetime && parseISO(a.valid.max?.datetime)) ||
          new Date(8640000000000000)
        );
      },
      function (a) {
        return parseISO(a.valid.min.datetime);
      },
    ],
    ["desc", "desc"]
  );
  // .sort((a, b) =>
  //   dateAsc(
  //     a.valid.max?.datetime || addYears(new Date(), 100),
  //     b.valid.max?.datetime || addYears(new Date(), 100)
  //   )
  // );

  $: upcomingParkingPermits = Object.values(
    activeRecentUpcomingPermits?.items || {}
  )
    .filter(
      (p) =>
        p.amenity == "parking" &&
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) < parseISO(p.valid.min.datetime)
    )
    .sort(comparer("valid.min.datetime", dateAsc));

  // aggregate fast and long past permits
  $: pastParkingPermits = Object.values(
    Object.assign(
      {},
      permitHistory?.items || {},
      activeRecentUpcomingPermits?.items || {}
    )
  )
    .filter(
      (p) =>
        p.amenity == "parking" &&
        (p.cancelled ||
          p.expired ||
          (p.valid.max &&
            Math.max(nowutc, Date.now()) > parseISO(p.valid.max.datetime)))
    )
    .sort(comparer("valid.max.datetime", dateDesc));

  $: activeAmenityPermits = orderBy(
    Object.values(permitHistory?.items || {}).filter(
      (p) =>
        p.amenity !== "parking" &&
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) > parseISO(p.valid.min.datetime) &&
        !(p.valid.max && parseISO(p.valid.max.datetime) < nowutc)
    ),
    [
      function (a) {
        //return Math.random();
        return (
          (a.valid.max?.datetime && parseISO(a.valid.max?.datetime)) ||
          new Date(8640000000000000)
        );
      },
      function (a) {
        return parseISO(a.valid.min.datetime);
      },
    ],
    ["desc", "desc"]
  );
  // .sort((a, b) =>
  //   dateAsc(
  //     a.valid.max?.datetime || addYears(new Date(), 100),
  //     b.valid.max?.datetime || addYears(new Date(), 100)
  //   )
  // );
  $: upcomingAmenityPermits = Object.values(permitHistory?.items || {})
    .filter(
      (p) =>
        p.amenity !== "parking" &&
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) < parseISO(p.valid.min.datetime)
    )
    .sort(comparer("valid.min.datetime", dateAsc));
  $: pastAmenityPermits = Object.values(
    Object.assign(
      {},
      permitHistory?.items || {},
      activeRecentUpcomingPermits?.items || {}
    )
  )
    .filter(
      (p) =>
        p.amenity !== "parking" &&
        (p.cancelled ||
          p.expired ||
          (p.valid.max &&
            Math.max(nowutc, Date.now()) > parseISO(p.valid.max.datetime)))
    )
    .sort(comparer("valid.max.datetime", dateDesc));

  // reroute if we get a different id
  $: if (url && item && id && item.id != id)
    route(`/properties/${item.scope}/${item.type}/${item.id}`, false);

  //$: console.log("status=", status);

  // $: itemStatus =
  //   item &&
  //   status &&
  //   [status.unitstatus?.["for"]?.[item.subject.id || item.subject]].filter(
  //     (status) => status && status.account == item.id
  //     //&& (status.check?.vacant || status.check?.reoccupied)
  //   );

  //$: console.log("item status =", itemStatus);

  onMount(function () {
    window.scrollTo(0, 0);
  });

  onDestroy(function () {});

  // no active checks, has status, is vacant with no pending occupancy, but has permits somehow
  $: specialReset =
    !statusAttention &&
    !!$tenantExternalCRMStatus?.status?.vacant &&
    //!$tenantExternalCRMStatus?.status?.pending?.occupancy &&
    !!permitHistory?.count;

  async function removeContact(item) {
    //await fetchRemoveContact(item.id);
    console.log("removing contact ", contacts?.items?.[item.id]);
    contacts?.items?.[item.id] && delete contacts.items[item.id];

    contacts = contacts;
  }
</script>

<section class="tenant detail record" data-record="tenant">
  <header>
    <h1>
      {#if item}
        <Record
          addressed={false}
          url={false}
          item={[item.subject, item].find((item) => item && item.id)}
        />
        <slot />
        <select
          name="tenant"
          value={item.subject?.id ?? item.subject}
          on:change={(e) =>
            route(
              `/properties/${$units.items[e.target.value].scope}/${
                e.target.name
              }/${e.target.value}`
            )}
        >
          {#if $units}
            {#each Object.values($units.items).sort(comparer("display", textAsc)) as item}
              <option value={item.id}>{item.display}</option>
            {/each}
          {/if}
        </select>
      {:else}
        Loading&hellip;
      {/if}
    </h1>
    {#if item}
      <ItemsList
        class="items"
        loading={false}
        items={[
          archived && {
            subject: item,
            latest: data.tenants.latest,
            type: "archived",
          },
        ].filter(identity)}
        types={{
          archived: RecordArchivedItem,
        }}
      />

      <!-- <time data-valid="PT5M"></time>	 -->

      {#if map && !minimal && property?.map}
        <PropertySelectedMap selected={item?.subject} />
      {/if}

      <dl>
        <!-- {#if !archived}
          {#if null == $tenantExternalCRMInfo}
            <dt>Integration</dt>
            <dd><Loading /></dd>
          {:else if $tenantExternalCRMInfo.connected}
            <dt>Synced from {$tenantExternalCRMInfo.status.service.name}</dt>
            <dd>
              <UnitStatusTimelineItem
                contextual={true}
                item={$tenantExternalCRMInfo?.status}
              />
            </dd>
          {:else}
            <dt>Integration</dt>
            <dd>Not configured</dd>
          {/if}
        {/if} -->
        {#if [donotpermit, ...Object.values(exceptions?.items || {}).filter((item) => item.active)].some(identity)}
          <dt>Status</dt>
          {#each [donotpermit].filter(identity) as item}
            <dd><PermittableAlertItem {item} /></dd>
          {/each}
          {#each Object.values(exceptions?.items || {}).filter((item) => item.active) as item}
            <dd><ViolationExceptionAlertItem {item} /></dd>
          {/each}
        {/if}
      </dl>
      <dl>
        <UnitFormatItem item={item?.subject} />

        <RecordVersionsItem
          item={{
            ...item,
            versions: data.tenants.versions,
            //type: "versions",
          }}
        />
        {#if current && !archived && false === $tenantExternalCRMStatus?.enabled}
          <dd>
            <TenantReset
              label="Move Out {proper(item?.format || item.type)}"
              {item}
            />
          </dd>
        {:else if current && !archived && specialReset}
          <dd>
            <TenantReset
              label="Reset {proper(item?.format || item.type)}"
              {item}
            />
            <!-- <a href="tenants/reset">Reset {item.format}</a> -->
          </dd>
        {/if}

        {#if property?.tenants.authentication}
          <dt>Passcode</dt>
          {#each auth as item}
            <dd>
              <CopyText value={item.display} />
            </dd>
            <!-- <dd>
              <Record url={false} {item} />
            </dd> -->
          {:else}
            <dd>
              <Record
                url={false}
                item={{
                  display: "--",
                }}
              />
              <!-- {#if current}<a href="tenant/authentication">Set</a>{/if} -->
            </dd>
          {/each}
        {/if}

        <!-- {#if current && property?.tenants.tour?.enabled}
          <dt>For the resident</dt>
          <dd>
            <a
              href="https://my.communityhq.app?account={item.id}"
              target="_blank">Resident Map & Guide</a
            >
          </dd>
        {/if} -->

        <dt>Synced</dt>
        <dd>
          <Time class="generated" datetime={data.generated} />
          <!-- <time datetime={data.generated}
            >{format(parseISO(data.generated), "MMM d yyyy h:mm a zzz")}</time
          > -->
        </dd>
      </dl>
    {/if}
    <!-- <aside class="timeline">
            <Timeline interval={valid} timezone={property?.timezone} permits={permits?.items} violations={violations?.items} conflicts={$conflicts?.items} accessed={$accessed?.items} scans={$scans} />
        </aside> -->
  </header>
  <!-- <section>
        <header>
            <h1>Payments</h1>
        </header>
        <PaymentMetrics data={$recordPaymentMetrics} />
    </section> -->
  {#if item}
    {#if $externalCRM?.enabled}
      <section class="crm">
        <header>
          <h1>Property Sync <ServiceLogo service={$externalCRM.service} /></h1>
          <!-- <ServiceTitle service={$externalCRM.service} /> -->
        </header>
        <ItemsList
          class="space service activity"
          items={[
            //$externalCRM.service,
            {
              type: "connected",
              //connected: $tenantExternalCRMInfo?.connected,
              service: $externalCRM.service,
              name: "unit",
              record:
                $tenantExternalCRMStatus?.connected &&
                $tenantExternalCRMStatus?.status?.unit &&
                `Linked to unit ${$tenantExternalCRMStatus?.status?.unit}`,
              //name: `unit ${$tenantExternalCRMInfo?.status?.unit}`,
            },
            $tenantExternalCRMStatus?.status,
          ]}
          loading={false}
          context={{
            record: unit.id,
          }}
          types={{
            connected: ServiceConnectedRecordItem,
            //service: ServiceSummaryItem,
            unitstatus: UnitStatusActionItem,
          }}
        />
        {#if !$tenantExternalCRMInfo}
          <Loading message="Loading synced info" />
        {:else if false === $tenantExternalCRMInfo.connected}
          <Warning message="No linked unit to sync" />
        {:else if $tenantExternalCRMInfo}
          {#each $tenantExternalCRMInfo.occupants as occupant}
            <ItemsList
              class="activity"
              items={[occupant, ...occupant.vehicles]}
              loading={false}
              context={{
                record: unit.id,
                occupant,
                tenant: item,
                policies: $policies,
                property,
              }}
              types={{
                resident: ResidentItem,
                vehicleinfo: VehicleItem,
              }}
            />
          {:else}
            <Info message="No occupant info" />
          {/each}

          <ItemsList
            class="rentable activity"
            items={$tenantExternalCRMInfo.rentals}
            loading={false}
            context={{
              record: unit.id,
            }}
            types={{
              rentable: RentableItem,
            }}
            highlight={{
              //rentable: true,
            }}><Info message="No rented items" /></ItemsList
          >
        {/if}
      </section>
      <!-- <section class="crm parking">
        <header>
          <h1>Synthesized Permits</h1>
        </header>
        <ItemsList
          class="items active"
          items={$tenantExternalCRMInfo?.permits?.items}
          context={{
            record: unit.id,
          }}
          highlight={{
            permit: true,
          }}
          classify={{
            permit: "active",
          }}
        />
      </section> -->
    {/if}
    {#if parking}
      <section class="parking">
        <header>
          <h1>Parking</h1>
          <nav>
            <ul>
              {#if permittable}
                <li>
                  <RecordPermitPolicies
                    record={item}
                    {property}
                    policies={$policies?.filter(
                      (p) => p.amenity === "parking" && p.audience.admin
                    )}
                  />
                </li>
              {/if}
              <!-- <li>
                <TenantNotify tenant={item} variant="parking" />
              </li> -->
            </ul>
          </nav>
        </header>
        <nav />
        <ItemsList
          class="items active"
          items={[
            activeParkingPermits?.length === 0 && {
              type: "permits",
              title: "Current Status",
              state: "active",
              count: activeParkingPermits.length,
              zero: "No Active Permits",
              datetime: activeRecentUpcomingPermits?.generated,
              timezone: activeRecentUpcomingPermits?.timezone,
            },
            ...activeParkingPermits,
          ].filter(identity)}
          types={{
            permits: CountSummaryItem,
            //"permit":PermitSummaryItem
          }}
          highlight={{
            permit: true,
          }}
          classify={{
            permit: "active",
          }}
        />
        <ItemsList
          class="info pending"
          items={upcomingParkingPermits}
          types={{
            //"permit":PermitSummaryItem
          }}
          highlight={{
            permit: true,
          }}
          classify={{
            permit: "pending",
          }}
        >
          <!-- <figure class="empty parking permits">No upcoming parking</figure> -->
        </ItemsList>

        <ItemsList
          class="info"
          items={[
            permitHistory && {
              type: "permits",
              title: "Past Year",
              state: "history",
              count: permitHistory && pastParkingPermits.length,
              //zero: "No Permits",
              interval: permitHistory?.valid,
              timezone: timezone,
            },
            ...pastParkingPermits,
          ]}
          types={{
            permits: CountSummaryItem,
          }}
          full={{
            permits: true,
          }}
        />
      </section>
    {/if}
    {#if amenities || amenitiesPolicies?.length}
      <section class="amenities">
        <header>
          <h1>Amenities</h1>
          <nav>
            <ul>
              {#if permittable}
                <li>
                  <RecordPermitPolicies
                    record={item}
                    {property}
                    policies={$policies?.filter(
                      (p) => p.amenity !== "parking" && p.audience.admin
                    )}
                  />
                </li>
                <!-- {#each $policies.filter((p) => p.amenity !== "parking" && p.audience.admin && p.tenant.request) as policy}
              <data value={policy.policy}>{policy.title}</data>
            {/each} -->
              {/if}
              <!-- <li>
                <TenantNotify tenant={item} variant="amenities" />
              </li> -->
            </ul>
          </nav>
          <!-- <PermitPolicySelector record={item} amenity="yes" /> -->
        </header>
        <ItemsList
          class="items active"
          items={[
            permitHistory &&
              activeAmenityPermits?.length === 0 && {
                type: "permits",
                title: "Current Status",
                state: "active",
                count: activeAmenityPermits.length,
                //zero: "No Active Permits",
                datetime: permitHistory.generated,
                timezone: timezone,
              },
            ...activeAmenityPermits,
          ].filter(identity)}
          types={{
            permits: CountSummaryItem,
          }}
          highlight={{
            permit: true,
          }}
          classify={{
            permit: "active",
          }}
        />
        <ItemsList
          class="info pending"
          items={upcomingAmenityPermits}
          types={{
            //"permit":PermitSummaryItem
          }}
          highlight={{
            permit: true,
          }}
          classify={{
            permit: "pending",
          }}
        >
          <!-- <figure class="empty parking permits">No upcoming parking</figure> -->
        </ItemsList>

        <ItemsList
          class="info"
          items={permitHistory &&
            [
              !!pastAmenityPermits.length && {
                type: "permits",
                title: "Past Year",
                count: pastAmenityPermits.length,
                zero: "No Permits",
                interval: permitHistory.valid,
                timezone: timezone,
              },
              ...pastAmenityPermits,
            ].filter(identity)}
          types={{
            permits: CountSummaryItem,
          }}
          full={{
            permits: true,
          }}
        />
      </section>
    {/if}
    {#if welcome}
      <section class="welcome">
        <header>
          <h1>Welcome</h1>
          <!-- <nav>
            <ul>
              <li>
                <a
                  href="https://my.communityhq.app?account={item.id}"
                  target="_blank">Resident Map & Guide</a
                >
                <Copy value="https://my.communityhq.app?account={item.id}" />
              </li>
            </ul>
          </nav> -->
          {#if current}
            <nav>
              <ul>
                <li>
                  <TenantNotify tenant={item} />
                </li>
              </ul>
            </nav>
          {/if}
        </header>
        <ComingSoon />
      </section>
    {/if}
    <section class="notes">
      <header>
        <h1>Notes & Info</h1>
        {#if current}
          <nav>
            <ul>
              <li>
                <ContactCreate
                  record={item}
                  on:create={({ detail }) => {
                    set(contacts, ["items", detail.id], detail);
                    contacts = contacts;
                  }}
                />
              </li>
              {#if !welcome}
                <li>
                  <TenantNotify tenant={item} />
                </li>
              {/if}
            </ul>
          </nav>
        {/if}
      </header>
      <ItemsList
        on:removed={(e) => removeContact(e.detail)}
        class="activity"
        items={Object.values(contacts.items ?? {})}
      />
      <RecordDetailsNotesFiles {item} {attachments} />
      <ItemsList
        class=""
        items={[unit]}
        types={{
          unit: UnitInfoItem,
        }}
      />
    </section>

    <section class="enforcement">
      <header><h1>Enforcement</h1></header>
      <ItemsList
        class="activity"
        items={[...Object.values(exceptions?.items || {})]}
        context={{
          record: item.id,
        }}
        types={{
          violationexception: ViolationExceptionStatusItem,
        }}
      />
      <ItemsList
        class="items"
        items={$enforcement && [$enforcement].filter(identity)}
        types={{
          enforcement: EnforcementDetailsItem,
        }}
        full={{
          enforcement: true,
        }}
        context={{
          record: id,
        }}
      />
      {#if !minimal}
        <ItemsList
          class="items"
          items={$enforcement && [$enforcement].filter(identity)}
          types={{
            enforcement: EnforcementSummaryMapItem,
          }}
          full={{
            enforcement: true,
          }}
          context={{
            record: id,
          }}
        />
      {/if}
      <ItemsList
        class="info"
        items={Object.values($enforcement?.items || {}).sort(
          comparer(sortByCreatedDate, dateDesc)
        )}
        full={{
          detection: true,
        }}
        context={{
          record: item.id,
        }}
      />
    </section>
    <section class="insights">
      <header>
        <h1>Smart Guard</h1>
        <!-- {#if current && !archived && !donotpermit}
          <nav>
            <ul>
              <li>
                <a href="donotpermit/tenant/new">Ban {item.format}&#x2026</a>
              </li>
            </ul>
          </nav>
        {/if} -->
        {#if current}
          <nav>
            <ul>
              {#if current && !donotpermit && property?.tenants?.permittable?.negative}
                <li><RecordDoNotPermit record={item} /></li>
              {/if}
              {#if property?.tenants?.authentication && current}
                <li>
                  <AuthCodeReset
                    record={item}
                    length={property.tenants.authentication}
                  />
                </li>
              {/if}
            </ul>
          </nav>
        {/if}
      </header>
      <ItemsList
        class="info"
        items={[...[donotpermit].filter(identity)]}
        context={{
          record: item.id,
        }}
        types={{}}
        highlight={{
          permittable: (item) => item.permittable === false,
        }}
      />
      <ItemsList
        class="activity"
        items={[
          ...Object.values($usage?.policies || {}),
          ...Object.values($usageFor?.items || {}),
        ]}
        types={{
          usage: UsageMeterSubjectItem,
        }}
      />
      <ItemsList
        class="info"
        items={[].concat(
          $conflicts || [],
          Object.values($conflicts?.items || {}).sort(
            comparer(sortByCreatedDate, dateDesc)
          )
        )}
        types={{
          conflicts: PermitConflictsDetailstem,
        }}
        full={{
          conflicts: true,
        }}
      />
      <!-- <ItemsList class="items" items={[].concat($conflicts || [])} types={{
            "conflicts":PermitConflictsDetailstem
        }} />
        <ItemsList class="items" items={Object.values($conflicts?.items || {}).sort(comparer(sortByCreatedDate, dateDesc))} />
        <ItemsList class="items" items={[].concat(Object.values($usage?.policies || {}))} types={{

        }} /> -->
    </section>
    <section>
      <header>
        <h1>Chargebacks</h1>
      </header>
      {#if $paymentsDisputed?.summary}
        <PaymentsDisputedSummary summary={$paymentsDisputed?.summary} />
      {/if}
      <ItemsList
        class="info"
        items={$paymentsDisputed?.items &&
          Object.values($paymentsDisputed?.items).sort(
            comparer("disputed.initiated.datetime", dateDesc)
          )}
        types={{ payment: PaymentDisputedItem }}
      />
    </section>
  {/if}
  <!-- <section>
        <header>
            <h1>Payments</h1>
        </header>
        <PaymentMetrics data={$recordPaymentMetrics} />
    </section> -->
</section>
