import {
  addDays,
  addHours,
  addMonths,
  areIntervalsOverlapping,
  endOfDay,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { derived } from "svelte/store";
import get from "lodash-es/get";
import range from "lodash-es/range";
import { comparer } from "../util/sort";
import {
  authcodeUpdated,
  donotpermit,
  mediaId,
  notesUpdated,
  permitsUpdated,
  permittablesUpdated,
  propertyId,
  spaceId,
  spacesUpdated,
  tenantId,
  externalCRMStatusData,
  unitsUpdated,
  vehicleId,
  violationsUpdated,
  externalCRMRentables,
  externalCRM,
  unitId,
  policies as allPolicies,
  externalCRMOccupancyPermits,
  externalCRMOccupancyData,
  paymentsDisputed as propertyPaymentsDisputed,
} from "./propertystores";
import { onlineVisibleTime, time } from "./timestores";
import { format } from "date-fns-tz";
import {
  fetchConflicts,
  fetchEnforcement,
  fetchMedia,
  fetchPermits,
  fetchTenant,
  fetchUsage,
  fetchVehicle,
  fetchSpace,
  fetchVehicleDetections,
  fetchSuspicious,
  fetchPaymentMetrics,
  fetchUnitExternalOccupancy,
} from "./api";
import { autocreate } from "./vehicle";
import { exceptions, usageMeters } from "$sections/EnforcementSection.svelte";
import { param } from "./params";
import { toDays } from "duration-fns";
import { policyCanPermitMedia, policyCanPermitSpace } from "./policy";

export const now = time({ minutes: 5 }); // always updating regardless of online

const validNow = onlineVisibleTime({ minutes: 1 });

const validShort = derived(
  validNow,
  ($now) =>
    format(addDays(startOfDay($now), -29), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

// this is the mostly-fixed valid...shouldn't change much
const valid = derived(
  validNow,
  ($now) =>
    format(addDays(startOfDay($now), -1200), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

//valid.subscribe(($value) => console.log("$valid=", $value));

const validPastWeek = derived(
  validNow,
  ($now) =>
    format(addDays(startOfDay($now), -7), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

const validPastSpace = derived(
  [validNow, param("past", true, "P7D")],
  ([$now, $past]) =>
    format(
      addDays(startOfDay($now), toDays($past) * -1),
      "yyyy-MM-dd'T'HH:mm:ss"
    ) +
    "/" +
    format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

const validPastMedia = derived(
  [time("PT1M")],
  ([$now]) =>
    "/"
);

const validIndefinite = derived(
  time("PT1M"),
  ($now) =>
    format(startOfDay(addDays($now, -364)), "yyyy-MM-dd'T'HH:mm:ss") + "/"
);

//validIndefinite.subscribe(($value) => console.log("$validindefinite=", $value));

export const vehicleStatus = derived(
  [
    propertyId,
    vehicleId,
    onlineVisibleTime({ seconds: 90 }),
    permitsUpdated(),
    violationsUpdated(),
    notesUpdated,
    permittablesUpdated(),
  ], // change on id and every 90s
  function ([$propertyId, $vehicleId], set) {
    if (!$propertyId) return set(null);
    if (!$vehicleId) return set(null);

    fetchVehicle(
      $propertyId,
      $vehicleId,
      format(addHours(new Date(), -1), "yyyy-MM-dd'T'HH:mm:ssxxx") + "/"
    ).then(set);
    //console.log("usage=", data);

    //set(data);
  }
);

export const recordPaymentMetrics = derived(
  [vehicleId, tenantId, onlineVisibleTime({ minutes: 30 })],
  ([$vehicleId, $tenantId], set) => {
    const $id = $vehicleId || $tenantId;
    if (!$id) return set(null);

    var byInterval = range(-14, 1, 1)
      .map((i) => [
        addMonths(startOfMonth(new Date()), i),
        addMonths(startOfMonth(new Date()), i + 1),
      ])
      .map(
        ([min, max]) =>
          `${format(min, "yyyy-MM-dd'T'00:00:00")}/${format(
            max,
            "yyyy-MM-dd'T'00:00:00"
          )}`
      )
      .map((valid) =>
        fetchPaymentMetrics(valid, {
          for: $id,
          datetimes: ["P1D", "P1M"],
          metrics: ["total", "policy", "property"],
          payments: ["total", "net"],
        })
      );

    Promise.all(byInterval)
      .then((arr) =>
        arr.reduce((json, json1) => {
          return Object.assign(json, json1, {
            metrics: {
              items: (json.metrics?.items || []).concat(
                json1.metrics.items || []
              ),
            },
          });
        }, {})
      )
      .then(set);

    // var json = await Promise.all([
    //   fetchPaymentMetrics(
    //   `${format(addMonths(new Date(), -2), "yyyy-MM-01'T'00:00:00")}/${format(
    //     addDays(new Date(), 1),
    //     "yyyy-MM-dd'T'00:00:00"
    //   )}`,
    //   {
    //     property: $propertyId,
    //     datetimes: ["P1M"],
    //     metrics: ["total", "policy", "property"],
    //     payments: ["total", "net"],
    //   }
    // ),
    // fetchPaymentMetrics(
    //   `${format(addMonths(new Date(), -1), "yyyy-MM-01'T'00:00:00")}/${format(
    //     addDays(new Date(), 1),
    //     "yyyy-MM-dd'T'00:00:00"
    //   )}`,
    //   {
    //     property: $propertyId,
    //     datetimes: ["P1M"],
    //     metrics: ["total", "policy", "property"],
    //     payments: ["total", "net"],
    //   }
    // ),
    // ;

    //console.log("paymentmetrics=", json);

    //set(json);
  }
);

// handle lookup for both tenant directly or latest-for-unit
export const tenantStatus = derived(
  [
    propertyId,
    tenantId,
    unitId,
    onlineVisibleTime({ seconds: 180 }),
    permitsUpdated(),
    violationsUpdated(),
    notesUpdated,
    permittablesUpdated(),
    authcodeUpdated(),
    unitsUpdated,
  ], // change on id and every 90s
  function ([$propertyId, $tenantId, $unitId], set) {
    if (!$propertyId) return set(null);
    if (!$tenantId && !$unitId) return set(null);

    fetchTenant(
      $propertyId,
      $tenantId || $unitId,
      format(addHours(new Date(), -1), "yyyy-MM-dd'T'HH:mm:ssxxx") + "/"
    ).then(set);
    //console.log("usage=", data);

    //set(data);
  }
);

export const spaceStatus = derived(
  [
    propertyId,
    spaceId,
    onlineVisibleTime({ seconds: 90 }),
    permitsUpdated(),
    notesUpdated,
    spacesUpdated,
  ], // change on id and every 90s
  function ([$propertyId, $spaceId], set) {
    if (!$propertyId) return set(null);
    if (!$spaceId) return set(null);

    fetchSpace(
      $propertyId,
      $spaceId,
      format(addHours(new Date(), -1), "yyyy-MM-dd'T'HH:mm:ssxxx") + "/"
    ).then(set);
    //console.log("usage=", data);

    //set(data);
  }
);

export const mediaStatus = derived(
  [
    propertyId,
    mediaId,
    onlineVisibleTime({ seconds: 90 }),
    permitsUpdated(),
    notesUpdated,
    permittablesUpdated(),
  ], // change on id and every 90s
  function ([$propertyId, $mediaId], set) {
    if (!$propertyId) return set(null);
    if (!$mediaId) return set(null);

    fetchMedia(
      $propertyId,
      $mediaId,
      format(addHours(new Date(), -1), "yyyy-MM-dd'T'HH:mm:ssxxx") + "/"
    ).then(set);
    //console.log("usage=", data);

    //set(data);
  }
);

// on property
// on interval (days)
// every 30min
export const suspicious = derived(
  [vehicleId, valid, onlineVisibleTime({ minutes: 30 })],
  function ([$vehicleId, $valid], set) {
    const $id = $vehicleId; // || $tenantId;
    if (!$id) return set(null);

    fetchSuspicious($id, $valid).then(set);
    //console.log("suspected=", suspected);

    //set(suspected);
  }
);

// on unit/vehicle
// on interval (days)
// every 30min
// permits updated
export const usage = derived(
  [tenantId, vehicleId, onlineVisibleTime({ minutes: 30 }), permitsUpdated()], // change on id and every 30m
  function ([$tenantId, $vehicleId], set) {
    const subject = $tenantId || $vehicleId;
    if (!subject) return set(null);

    fetchUsage(subject).then((data) => set(data.usage?.["for"]?.[subject]));
    //console.log("usage=", data);
  }
);

// on unit/vehicle
// on interval (days)
// on violations updated
// every 10min
export const enforcement = derived(
  [
    tenantId,
    vehicleId,
    mediaId,
    valid,
    violationsUpdated(),
    onlineVisibleTime({ minutes: 10 }),
  ], // update on vehicle change, day change, violations updated, every 10m
  function ([$tenantId, $vehicleId, $mediaId, $valid], set) {
    const subject = $tenantId || $vehicleId || $mediaId;
    if (!subject) return set(null);

    // do we clear this on any change?

    //console.log("updating enforcement init for=", subject, $valid);

    fetchEnforcement(subject, $valid, false, "P1D").then(function (data) {
      set(data.enforcement?.["for"]?.[subject]);
    });

    //enforcementFor.set(data.enforcement?.["for"]);

    // succeeded...there must be none
  }
);

// only used for permit history
// on unit/vehicle
// on interval (days)
// NOT on permits updated
// every 10min
export const permits = derived(
  [
    tenantId,
    vehicleId,
    mediaId,
    spaceId,
    validIndefinite,
    validPastSpace,
    validPastMedia,
    permitsUpdated(),
    onlineVisibleTime({ minutes: 1 }),
  ], // update on vehicle change, day change, violations updated, every 10m
  function (
    [$tenantId, $vehicleId, $mediaId, $spaceId, $valid, $validSpace, $validMedia],
    set
  ) {
    const $id = $tenantId || $vehicleId || $mediaId || $spaceId;
    if (!$id) return set(null);

    // do we clear this on any change?

    //console.log("updating enforcement init for=", subject, $valid);

    fetchPermits($id, $spaceId ? $validSpace : $mediaId ? $validMedia : $valid).then((data) =>
      set(data.permits || data)
    );

    //enforcementFor.set(data.enforcement?.["for"]);

    // succeeded...there must be none
  }
);

/// xxxFor are piggybacking on other "status" stores

export const usageFor = derived(
  [vehicleId, tenantId, usageMeters],
  function ([$vehicleId, $tenantId, $usage], set) {
    const $id = $tenantId || $vehicleId;
    if (!$id) return set(null);
    if (!$usage) return set(null);

    //console.log("usagefor=", $id, $usage, $usage[$id]);

    return set($usage[$id] || {});
  }
);

export const exceptionsFor = derived(
  [vehicleId, tenantId, exceptions],
  function ([$vehicleId, $tenantId, $exceptions], set) {
    const $id = $tenantId || $vehicleId;
    if (!$id) return set(null);
    if (!$exceptions) return set(null);

    //console.log("usagefor=", $id, $usage, $usage[$id]);

    return set($exceptions["for"][$id] || {});
  }
);

export const doNotPermitFor = derived(
  [vehicleId, tenantId, donotpermit],
  function ([$vehicleId, $tenantId, $permittables], set) {
    const $id = $tenantId || $vehicleId;
    if (!$id) return set(null);
    if (!$permittables) return set(null);

    //console.log("usagefor=", $id, $usage, $usage[$id]);

    return set($permittables["for"][$id] || {});
  }
);

// on unit/vehicle
// on interval (days)
// every 30min
export const conflicts = derived(
  [tenantId, vehicleId, valid, onlineVisibleTime({ minutes: 30 })],
  function ([$tenantId, $vehicleId, $valid], set) {
    const subject = $tenantId || $vehicleId;
    if (!subject) return set(null);

    fetchConflicts(
      subject,
      $valid
      //`${format(addDays(new Date(), -30), "yyyy-MM-dd'T'HH:mm:ssxxx")}/`
    ).then((data) => set(data.conflicts));
    //console.log("usage=", data);
  }
);

// on unit/vehicle
// on interval (days)
// every 30min
// notes updated
// export const notes = derived(
//   [tenantId, vehicleId, valid, onlineVisibleTime({ minutes: 30 }), notesUpdated],
//   async function ([$tenantId, $vehicleId, $valid], set) {
//     const subject = $tenantId || $vehicleId;
//     if (!subject) return set(null);

//     const data = await fetchNot(
//       subject,
//       $valid
//       //`${format(addDays(new Date(), -30), "yyyy-MM-dd'T'HH:mm:ssxxx")}/`
//     );
//     //console.log("usage=", data);

//     set(data.conflicts);
//   }
// );

function files(id, payload) {
  return Object.keys(get(payload, ["attachments", "for", id, "items"], {}))
    .map((k) => payload.items[k])
    .filter((i) => i && "file" == i.type);
}

function processScans(data) {
  if (!data) return data;

  var json = data;

  var items = Object.keys(json.detections?.items || {})
    .map((id) => {
      var item = json.items[id];
      if (!item) return;
      item.observation = json.items[item.observation] || item.observation;
      if (item.observation && item.observation.id) return null;
      item.files = files(id, json);

      item.vehicle =
        json.items[item.vehicle] || autocreate(item.vehicle, item.scope);

      item.created.by =
        item.created.by && (json.items[item.created.by] || item.created.by);
      if (!item.created.by.id) return null; // must be created by someone

      return item;
    })
    .concat(
      Object.keys(json.observations?.items || {}).map((id) => {
        var item = json.items[id];
        if (!item) return;
        //item.observation = json.items[item.observation] || item.observation;
        //if(item.observation && item.observation.id) return null;
        if (!item.media) return;
        item.media = json.items[item.media] || item.media;
        item.files = files(id, json);

        item.created.by =
          item.created.by && (json.items[item.created.by] || item.created.by);
        if (!item.created.by.id) return; // must be created by someone

        return item;
      })
    )
    .filter(function (item) {
      if (!item) return false;
      item.subjects = [item.vehicle, item.media].filter((i) => i);
      if (!item.subjects.length) return false;
      return true;
    });

  //items.sort((a, b) => parseISO(b.created.utc) - parseISO(a.created.utc));

  return items;
}

// TODO: how do we update trigger this? update state?

export const scans = derived(
  [vehicleId, valid],
  function ([$vehicle, $valid], set) {
    //console.log("$vehicle=", $vehicle);

    if (!$vehicle) return set(null);

    fetchVehicleDetections($vehicle, $valid).then((json) => {
      var scans = processScans(json);
      set(scans);
    });
  }
);

function timesOverlap(a, b) {
  if (!a || !b) return false;
  const intervalA = {
    start: a.start ?? new Date(100, 0, 1),
    end: a.end ?? new Date(5000, 0, 1),
  };
  const intervalB = {
    start: b.start ?? new Date(100, 0, 1),
    end: b.end ?? new Date(5000, 0, 1),
  };
  return areIntervalsOverlapping(intervalA, intervalB);
}

function reduceToInterval(interval, date, i) {
  if (i === 0 && date) interval.start = date;
  else if (i === 1 && date) interval.end = date;
  return interval;
}
export function utcInterval(input) {
  if (!input) return input;
  return input
    .split("/")
    .map((str) => str && parseISO(str))
    .reduce(reduceToInterval, {
      start: new Date(100, 0, 1),
      end: new Date(5000, 0, 1),
    });
}

export const spaceExternalCRMInfo = derived<
  [typeof spaceId, typeof externalCRM, typeof externalCRMStatusData],
  {
    enabled: boolean;
    service: unknown;
    connected: boolean;
    rentables: unknown[];
  }
>([spaceId, externalCRM, externalCRMStatusData], ([$id, $crm, $data]) => {
  if (!$id) return null;
  if (null == $data) return null;

  const spacedata = $data?.rentables?.for?.[$id];

  console.log("spacecrm=", spacedata);

  return {
    enabled: $crm.enabled,
    service: $crm.service,
    connected: !!spacedata,
    rentables: Object.values(spacedata?.items ?? {}).map(
      (value) => $data.items[value as string] ?? value
    ),
  };
});

export const tenantExternalCRMStatus = derived<
  [typeof tenantStatus, typeof externalCRM, typeof externalCRMStatusData],
  {
    subject: Unit | string;
    enabled: boolean;
    service: unknown;
    connected: boolean;
    status: unknown;
  }
>(
  [tenantStatus, externalCRM, externalCRMStatusData],
  ([$tenantStatus, $crm, $unitStatus], set) => {
    const item = $tenantStatus?.tenants?.item;
    const subject = item?.subject;
    if (null == item || null == $unitStatus) return set(null); // no status

    const status = $unitStatus.unitstatus?.["for"]?.[subject.id || subject];

    return set({
      //...status,
      subject,
      enabled: $crm.enabled,
      connected: !!status,
      service: $crm.service,
      status,
    });
  }
);

export const tenantExternalCRMInfo = derived<
  [typeof tenantExternalCRMStatus, typeof externalCRMOccupancyData],
  {
    type: string;
    subject: Unit | string;
    enabled: boolean;
    service: unknown;
    connected: boolean;
    status: unknown;
    occupants?: unknown[];
    rentals?: unknown[];
    permits?: Permits;
  }
>(
  [tenantExternalCRMStatus, externalCRMOccupancyData],
  ([$crmStatus, $crmOccupancy], set) => {
    const subject = $crmStatus?.subject;
    if (null == $crmStatus || null == subject) return set(null); // no status

    const id = (subject as Unit).id || (subject as string);
    //console.log("data=", data, item);

    if (!$crmStatus.connected) {
      return set({
        type: "occupancy",
        ...$crmStatus,
      });
    }

    // we can replace this with property occupancy data

    //fetchUnitExternalOccupancy(id).then(function (json) {
    const json = $crmOccupancy;
    var occupied = json?.occupancy?.for[id];
    console.log("occupancy=", occupied, $crmOccupancy);
    if (!occupied) return null;

    const occupants = Object.values(
      occupied.current?.occupants ?? occupied.pending?.occupants ?? {}
    )
      .map((value: any) => {
        value = occupied.items[value as string];
        const occupant = json.items[value.occupant] ?? value.occupant;
        return {
          ...value,
          ...occupant,
          vehicles: Object.values(
            value.current?.vehicles ?? value.pending?.vehicles ?? {}
          ).map((value) => json.items[value as string]),
        };
      })
      .sort(comparer("name"));

    const rentals = Object.values(
      occupied.current?.rentals ?? occupied.pending?.rentals ?? {}
    ).map((value: any) => {
      value = occupied.items[value as string];
      const rentable = json.items[value.rentable as string] ?? value.rentable;
      return {
        ...value,
        ...rentable,
        //subject: json.items[rentable.subject] ?? rentable.subject,
      };
    });

    console.log("occupants=", occupants, "rented=", rentals);

    return set({
      type: "occupancy",
      ...$crmStatus,
      occupants,
      rentals,
      permits:
        $crmOccupancy?.permits?.for?.[id] ??
        ($crmOccupancy?.permits?.for && {
          type: "permits",
          subject: id,
          items: {},
        }),
    });
    //});
  }
);

export const policies = derived<
  [
    typeof propertyId,
    typeof spaceId,
    typeof mediaId,
    typeof unitId,
    typeof vehicleId,
    typeof tenantId,
    typeof mediaStatus,
    typeof spaceStatus,
    typeof tenantStatus,
    typeof allPolicies,
  ],
  PermitIssuePolicy[]
>(
  [
    propertyId,
    spaceId,
    mediaId,
    unitId,
    vehicleId,
    tenantId,
    mediaStatus,
    spaceStatus,
    tenantStatus,
    allPolicies,
  ],
  function updater([
    $propertyId,
    $spaceId,
    $mediaId,
    $unitId,
    $vehicleId,
    $tenantId,
    $mediaStatus,
    $spaceStatus,
    $tenantStatus,
    $policies,
  ]) {
    if (!$propertyId || !$policies) return null;

    // special case vehicle
    if ($vehicleId) {
      return $policies.filter(
        (policy) => policy?.vehicle?.request && policy.audience.admin
      );
    }
    // special case tenant (get for unit)
    // if ($tenantStatus && $data) {
    //   const item = $tenantStatus?.tenants?.item;
    //   return Object.keys(
    //     $data["for"][item.subject.id || item.subject]?.items ?? {}
    //   )
    //     .map((item) => $data.items[item])
    //     .concat($legacy)
    //     .filter((policy) => policy?.tenant?.request && policy.audience.admin);
    // }

    // pull media policies
    if ($mediaId) {
      if (!$mediaStatus) return null;
      //console.log("$mediastatus=", $mediaStatus);
      const media = $mediaStatus?.media?.item;
      if (!media) return null;
      return $policies.filter(
        (policy) =>
          policy?.media?.request &&
          policy?.audience?.admin &&
          policyCanPermitMedia(policy, media)
      );
    }

    if ($spaceId) {
      if (!$spaceStatus) return null;
      const space = $spaceStatus?.spaces?.item;
      if (!space) return null;
      return $policies.filter(
        (policy) =>
          !!policy?.space?.request &&
          !!policy?.audience?.admin &&
          policyCanPermitSpace(policy, space)
      );
    }

    if ($tenantId) {
      if (!$tenantStatus) return null;
      //console.log("$tenantstatus=", $tenantStatus);
      const tenant = $tenantStatus?.tenants?.item;
      if (!tenant) return null;
      return $policies.filter(
        (policy) =>
          policy?.tenant?.request &&
          policy?.audience?.admin &&
          (null == policy.units?.items ||
            policy.units.items[tenant.subject?.id ?? tenant.subject])
      );
    }

    const $id = $spaceId || $mediaId || $unitId || $tenantId;
    if (!$id || !$policies) return null; // loading

    //console.log("legacy=", $legacy);

    return $policies.filter((policy) => {
      return (
        policy?.[$spaceId ? "space" : $mediaId ? "media" : "tenant"]?.request &&
        policy.audience.admin
      );
    });
    //.filter((item) => item.amenity == "parking");
  }
);

export const paymentsDisputed = derived(
  [
    propertyId,
    spaceId,
    mediaId,
    unitId,
    vehicleId,
    tenantId,
    propertyPaymentsDisputed
  ],
  function updater([
    $propertyId,
    $spaceId,
    $mediaId,
    $unitId,
    $vehicleId,
    $tenantId,
    $propertyPaymentsDisputed,
  ]) {
    if (!$propertyId || !$propertyPaymentsDisputed) return null;


    return $propertyPaymentsDisputed?.["for"]?.[$vehicleId ?? $tenantId ?? $unitId ?? $spaceId] ?? {
      type: "payments",
      subject: $vehicleId ?? $tenantId ?? $unitId ?? $spaceId,
      count: 0,
      summary: {
        //generated = now.ToISO8601(),
        type: "summary",
        items: {
          disputed: {
            type: "disputed",
            title: "Disputed",
            count: 0,
            money: {
              currency: "usd",
              display: "$0.00",
              value: 0
            },
          },
          fees: {
            type: "fees",
            title: "Bank Fees",
            count: 0,
            money: {
              currency: "usd",
              display: "$0.00",
              value: 0
            },
          },
        },
      },
      items: {},
    };


    //.filter((item) => item.amenity == "parking");
  }
);