<script>
  import {
    propertyPaymentMetrics,
    policies,
    stripe,
    violationPolicies,
    paymentsDisputed,
  } from "$utils/propertystores";
  import ItemsList from "$components/item/ItemsList.svelte";
  import PaymentMetrics from "$components/PaymentMetrics.svelte";
  import PolicyPricingItem from "$components/PolicyPricingItem.svelte";
  import { help, message } from "$utils/help";
  import Time from "$components/Time.svelte";
  import { comparer, dateDesc } from "$utils/sort";
  import startCase from "lodash-es/startCase";
  import PaymentDisputedItem from "$components/payment/PaymentDisputedItem.svelte";
  import PaymentsDisputedSummary from "$components/payment/PaymentsDisputedSummary.svelte";
  export let property;

  $: pricingPolicies = ($policies || []).filter(
    (item) => true === item.issue?.enabled
  ); // && $policies.filter(item => item.pricing)) || []
  //$: console.log("policies=", $policies);
  $: disabled =
    $policies && !pricingPolicies.filter((item) => item.pricing).length;

  //document.documentElement.setAttribute("data-records", "payments");
</script>

<section class="payments">
  <header>
    {#if $propertyPaymentMetrics?.generated}
      <h1>Payments</h1>
      <dl>
        {#each pricingPolicies as item}
          <dt>{item.title}</dt>
          <dd>
            <p>{(item.pricing && item.pricing.description) || "No charge"}</p>
          </dd>
        {/each}
        {#each $violationPolicies ?? [] as item}
          <dt>{startCase(item.title.toLowerCase())}</dt>
          <dd>
            <p>{(item.prices?.count && item.description) || "No charge"}</p>
          </dd>
        {/each}
        <dt>Payment Processing</dt>
        {#if !$stripe}
          <dd>Loading&hellip</dd>
        {:else if $stripe?.account}
          <dd>Connected Stripe account</dd>
        {:else}
          <dd>Full-service payments</dd>
          <!-- <dd>
            <a
              href="https://services.communityboss.app/stripe/connect?state={property?.id}"
              target="_blank">Connect Stripe account&hellip</a
            >
          </dd> -->
        {/if}
        <dt>Synced</dt>
        <dd><Time datetime={$propertyPaymentMetrics?.generated} /></dd>
      </dl>
    {:else}
      <h1>Loading&hellip;</h1>
    {/if}
    <aside class="help">
      <p>
        <a
          on:click={help}
          href="mailto:help@communityboss.com?subject=Parking Payments&body=I'd like to learn more about adding payments to Parking Boss"
          >I'd like to learn more</a
        >
      </p>
    </aside>
  </header>
  {#if !disabled}
    <section>
      <header>
        <h1>Chargebacks</h1>
      </header>
      {#if $paymentsDisputed?.summary}
        <PaymentsDisputedSummary summary={$paymentsDisputed?.summary} />
      {/if}
      <ItemsList
        class="info"
        items={$paymentsDisputed?.items &&
          Object.values($paymentsDisputed?.items).sort(
            comparer("disputed.initiated.datetime", dateDesc)
          )}
        types={{ payment: PaymentDisputedItem }}
      />
    </section>
  {/if}
  {#each [{ title: "All Revenue" }, ...Object.values($propertyPaymentMetrics?.items || {})
      .filter((item) => item.policy && "parking" == item.amenity)
      .sort(comparer("title")), ...Object.values($propertyPaymentMetrics?.items || {})
      .filter((item) => item.policy && item.violation)
      .sort(comparer("title")), ...Object.values($propertyPaymentMetrics?.items || {})
      .filter((item) => item.amenity && "parking" != item.amenity)
      .sort(comparer("title"))] as policy}
    <section class="policy">
      <header>
        <h1>
          <data value={policy.policy || JSON.stringify(policy)}
            >{policy.title}</data
          >
        </h1>
      </header>
      <PaymentMetrics data={$propertyPaymentMetrics} group={policy.policy} />
    </section>
  {/each}

  {#if disabled}
    <aside class="payments">
      <header>
        <h1>Want to generate parking revenue?</h1>
      </header>

      <p>
        Guest parking is a valuable asset and amenity for your residents, but
        unlimited use doesn’t have to always be free. Parking Boss’ flexible
        rules and payment engine allows you to give residents just the right
        amount of complimentary guest parking and charge those who wish to use
        more. We’ll help you implement the right strategy that aligns with your
        community rules and values.
      </p>
      <nav>
        <a
          href="https://www.luminousresidential.com/products/parking-boss/pay-to-park?utm_source=parkingmanager-web&utm_campaign=parkingpayments"
          target="_blank">Learn More</a
        >
        <a
          href="mailto:help@communityboss.com?subject=Parking Payments&body=Interested in adding payments"
          on:click={(e) => message("I'm interested in adding payments")}
          >I'm Interested…</a
        >
      </nav>
      <h1>A few examples:</h1>
      <figure>
        <ul>
          <!-- heather hill -->
          <!-- <li>
            
            <img
              src="https://upload.parkingboss.com/cdn-cgi/image/w=500,format=auto/https://assets.website-files.com/61a183fce0905604596fd721/61afddeb647ec0f1a6d1ba18_heather-hill.jpg"
            />
            <h1>459 unit community</h1>
            <p>Free day parking (7am-11pm) then $5/night (11pm-7am)</p>
            <data value="15600.00">$15,600 Average Annual Profit</data>
          </li> -->
          <!-- palmetto promenade -->
          <li>
            <img
              src="https://upload.parkingboss.com/cdn-cgi/image/w=500,format=auto/https://assets.website-files.com/61a183fce0905604596fd721/61afde1d14cfca428981919b_palmetto-promenade.jpg"
            />
            <h1>378 unit community</h1>
            <p>Free 24 hrs/unit/mo parking then $2/hr ($10 max/day)</p>
            <data value="20126.00">$20,126 Average Annual Profit</data>
          </li>
          <!-- ? -->
          <li>
            <img
              src="https://upload.parkingboss.com/cdn-cgi/image/w=500,format=auto/https://assets.website-files.com/61a183fce0905604596fd721/61b157aa342b003f3ebd8cbe_lms.jpg"
            />
            <h1>418 unit community</h1>
            <p>Free 48 hrs/unit/mo guest parking then $2/hr ($7 max/day)</p>
            <data value="30000.00">$30,600 Average Annual Profit</data>
          </li>
          <!-- platform -->
          <li>
            <img
              src="https://upload.parkingboss.com/cdn-cgi/image/w=500,format=auto/https://assets.website-files.com/61a183fce0905604596fd721/61afd27d647ec07b76d176f4_platform.jpg"
            />
            <h1>324 unit community</h1>
            <p>Free 72 hrs/unit/mo parking then $3/hr</p>
            <data value="30368.00">$30,368 Average Annual Profit</data>
          </li>
          <!-- gateway gardens -->
          <li>
            <img
              src="https://upload.parkingboss.com/cdn-cgi/image/w=500,format=auto/https://assets.website-files.com/61a183fce0905604596fd721/61b15532d1cd171bae066626_gateway-gardens.jpg"
            />
            <h1>566 unit community</h1>
            <p>Paid guest parking $6/24 hrs</p>
            <data value="57723.00">$57,723 Average Annual Profit</data>
          </li>
        </ul>
      </figure>
    </aside>
  {/if}
</section>
